import * as React from 'react';

export const GHcontributions = () => {
    return(

        <section className="GH" id ="GHcontributions">
            <div className="GHbox" id = "GHbox">
                <h2>
                    GitHub Contributions
                </h2>
                <img src="https://ghchart.rshah.org/Mugwash" alt="Mugwash's Github chart" style={{ width: '1500px', height: '300px' }} />
            </div>

        </section>

    )
    };

