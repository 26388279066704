import * as React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import Blogs from "./pages/blogs";
import NavBar2 from "./components/NavBar2";
import {Banner} from "./components/Banner";
import {Skills} from "./components/Skills";
import {Projects} from "./components/Projects";
import {GHcontributions} from "./components/GH";
import {Stacks} from "./components/TechStack";
import blogs from './pages/blogs';
import { BrowserRouter as Router, Switch, Route,Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div id="home-id" />
      <NavBar />
      <Banner />
      <Skills />
      <Stacks />
      <Projects />
      <div id="feature">
        <GHcontributions />
      </div>
    </div>
  );
}

export default App;
